import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table, Tabs, Tab, Modal } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import data, { key_local_storage_user, web_url } from '../../lib/backend/data'
import { confirmAlert } from 'react-confirm-alert'
import 'moment/locale/es';
import PantallaCargando from '../../subComponents/general/pantalla_cargando'
import { formatDateHoy, formatDateYear } from '../../lib/helpers/dates'
import ReactToPrint from 'react-to-print'
import { fechaATexto } from '../../lib/helpers/helpers'

const BoxImprimir = React.forwardRef((props, ref) => {
    const datos = props.datos
    return (
      <div ref={ref} className="p-3">
       <Row>
                <Col>
                    <h5>Cantidad SKU</h5>
                    <h2>{Intl.NumberFormat("es-ES").format(datos.cantidad_productos)}</h2>
                </Col>
                <Col>
                    <h5>Cantidad unidades</h5>
                    <h2>{Intl.NumberFormat("es-ES").format(datos.cantidad_productos)}</h2>
                </Col>
                <Col>
                    <h5>Venta total</h5>
                    <h2>${Intl.NumberFormat("es-ES").format(datos.venta_total)}</h2>
                </Col>
            </Row>
            <hr />
            <Row>
            {
                datos.productos.map((pro,i) => {
                    return <Col key={`pro-${i}`} md={4}>
                        <a href={`${web_url}/?p=${pro.id}`} target="_blank"><h4 style={{ color: 'black' }}>{pro.name}</h4></a>
                        <h6 style={{ fontWeight: 100 }}>SKU: <b>{pro.sku}</b></h6>
                        <h6 style={{ fontWeight: 100 }}>TOTAL: <b>${Intl.NumberFormat("es-ES").format(pro.total)}</b></h6>
                        {
                            pro.inventarios.map((inv,pos) => {
                                return <div key={`inv-${i}-${pos}`}>
                                    <h6 style={{ fontWeight: 100 }}>{inv.nombre}: <b>{inv.stock}</b></h6>
                                </div>
                            })
                        }
                        <hr />
                    </Col>
                })
            }
            </Row>
      </div>
    );
});

class InformeVentas extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadingUsuarios: false,
            loadingMore: false,
            total: 0,
            usuarios: [],
            parametro_busqueda: '',
            showModal: false,
            loadingBusqueda: false,
            filtro_rol: '',
            fecha: formatDateHoy(new Date()),
            datos: false,
            reportes_inventario: [],
            bodega_principal: "BODEGACENTRAL",
            token_defontana: "",
            emitiendoDefontana: false,
            hora: "",
            horahasta: "",
            traspaso_seleccionado: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)
        this.handleChangeFecha = this.handleChangeFecha.bind(this)
        this.handleChangeProductoMovimiento = this.handleChangeProductoMovimiento.bind(this)

    }

    handleChangeFecha(e) {
        const { value } = e.target
        //this.getUsuarios(value)
        return this.setState({ fecha: value })
    }

    handleChangeProductoMovimiento(e) {
        const { reportes_inventario } = this.state
        const { name, value } = e.target
        let pos_movimiento = e.target.getAttribute("pos_movimiento")
        pos_movimiento = parseInt(pos_movimiento)
        let i = e.target.getAttribute("i")
        i = parseInt(i)
        let count = parseInt(value)
        if(isNaN(count) !== false) count = 0
        if(!reportes_inventario[pos_movimiento]) return false
        if(!reportes_inventario[pos_movimiento].payload.details[i].original_count){
            reportes_inventario[pos_movimiento].payload.details[i].original_count = reportes_inventario[pos_movimiento].payload.details[i].count
        }
        console.log(reportes_inventario[pos_movimiento].payload.details[i])
        reportes_inventario[pos_movimiento].payload.details[i].count = count
        return this.setState({ reportes_inventario }) 
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    buscar(){
        const { parametro_busqueda, user } = this.state
        if(!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if(parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/search`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.length < 1){
                toast.warn('No se encontraron resultados')
                return this.setState({ [loading]: false })
            }
            return this.setState({ usuarios: res, filtro_rol:'', [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    payloadDefontanaInventario(reportes) {
        if(reportes.length < 1) return []
        const { bodega_principal } = this.state
        const date = new Date().toISOString()
        const datos = reportes.map(reporte => {
            const fiscalYear = formatDateYear(new Date())
            const payload = {
                documentTypeId: "TB",
                fiscalYear,
                clientId: "",
                originStowageId: reporte.codigo_defontana,
                destinationStowageId: bodega_principal,
                reasonId: "TRASPASO",
                total: 1,
                isCentralizable: false,
                analysis: {
                  "businessCenter": "",
                  "clasifier1": "",
                  "clasifier2": "",
                  "file": "",
                  "fileFieldName": ""
                },
                referenceDocumentType: "",
                date,
                externalDocumentID: "",
                details: reporte.productos
            }
            return { nombre: reporte.nombre, payload}
        })
        return datos.filter(mov => mov.payload.originStowageId !== bodega_principal)
    }

    procesarTraspasosBodega(productos) {

        let bodegas = []

        for( const pro of productos ){
            if(!pro.inventarios) continue
            if(Array.isArray(pro.inventarios) !== true) continue
            if(pro.inventarios.length < 1) continue

            for( const inv of pro.inventarios ){

                const i_bodega = bodegas.findIndex(b => b.id === inv.id)

                if(i_bodega < 0) {

                    bodegas.push({
                        id: inv.id,
                        nombre: inv.nombre,
                        codigo_defontana: inv.codigo_defontana,
                        productos: [
                            {
                                articleId: pro.sku,
                                description: pro.name,
                                count: inv.stock,
                                comment: "",
                                price: pro.total
                            }
                        ]
                    })

                } else {

                    const i_producto = bodegas[i_bodega].productos.findIndex(producto => producto.articleId === pro.sku)

                    if(i_producto < 0){

                        bodegas[i_bodega].productos.push({
                                articleId: pro.sku,
                                description: pro.name,
                                count: inv.stock,
                                comment: "",
                                price: pro.total
                        })
                        
                    } else {

                        bodegas[i_bodega].productos[i_producto].count = bodegas[i_bodega].productos[i_producto].count + inv.stock
                        
                    }
                }
            }
        }

        return bodegas.filter(b => b.codigo_defontana)
    }

    confirmarTraspaso(i) {
        const { reportes_inventario } = this.state
        console.log(reportes_inventario[i])
        return confirmAlert({
            title: 'Confirmar',
            message: `Estás a punto de generar un traspaso de inventario en Defontana, esta acción no se puede deshacer desde este sistema`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.emitirTraspaso(i)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    async emitirTraspaso(i){
        const { reportes_inventario, fecha, hora, horahasta, user } = this.state
        let payload = reportes_inventario[i]
        payload.meta = {
            nombre: reportes_inventario[i].nombre,
            fecha,
            desde: hora,
            hasta: horahasta
        }
        let loading = 'emitiendoDefontana'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/defontana/stock/new-doc`,{
            method:'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            } else if(res.number){
                toast.success(`Emitido el documento ${res.number} exitosamente`)
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getUsuarios(){
        const { fecha, user, hora, horahasta } = this.state
        if(!horahasta) return toast.error("horahasta es requerido")
        if(!hora) return toast.error("hora es requerido")
        if(!fecha) return toast.error("fecha es requerido")

        let loading = 'loadingUsuarios'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/woo/list`,{
            method:'POST',
            body: JSON.stringify({
                fecha,
                hora,
                horahasta
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            } else {

                if(res){
                    if(typeof res === "object"){
                        if(res.productos){
                            if(Array.isArray(res.productos) !== false){
                                const procesar_reportes = this.procesarTraspasosBodega(res.productos)
                                const reportes_inventario = this.payloadDefontanaInventario(procesar_reportes)
                                console.log(reportes_inventario)
                                this.setState({ reportes_inventario })
                            }
                        }
                    }
                }
                this.setState({ datos: res, [loading]: false })
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    buscarUsuario(e){
        e.preventDefault()
        return this.buscar()
    }
    
    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={12}>
                <h4>No hay datos que mostrar</h4>
                <p>Esta página no contiene datos suficientes para visualizar esta página</p>
                </Col>
            </Row>
        </div>
    }

    visualizarMovimientoBodegaProductos(productos,pos){
        return <div>
            {
                productos.map((pro,ipro) => {
                    return <div className='mb-3' key={`prod-${pro.articleId}`}>
                        <h6>{pro.description}</h6>
                        <input className='form-control' type="number" pos_movimiento={pos} i={ipro} defaultValue={pro.count} onChange={this.handleChangeProductoMovimiento} />
                    </div>
                })
            }
        </div>
    }

    handleClose(){
        return this.setState({ traspaso_seleccionado: false, showModal: false })
    }

    checkQuantity(producto){
        if(!producto.original_count) return <i className="text-success fas fa-check-circle"></i>
        let enviado = parseInt(producto.count)
        let original = parseInt(producto.original_count)
        if(isNaN(enviado) || isNaN(original)) return <i class="text-warning fas fa-exclamation-triangle"></i>
        if(enviado < original) return <i class="text-danger fas fa-minus-circle"></i>
        if(enviado > original) return <i class="text-primary fas fa-plus-circle"></i>
        if(enviado === original) return <i className="text-success fas fa-check-circle"></i>
    }

    mostrarProductosTraspaso(productos){
        if(!productos) return <h4>Sin detalles de productos</h4>
        if(Array.isArray(productos) !== true) return <h4>Sin detalles de productos</h4>
        if(productos.length < 1) return <h4>Sin productos</h4>
        return <div>
            <Row className="mb-3">
                        <Col md={8}>Producto</Col>
                        <Col md={2}>Estado/Stock original</Col>
                        <Col md={2}>Enviado</Col>
                    </Row>
            {
                productos.map((pro,i) => {
                    return <Row key={`pro-${i}`} className="mb-3">
                        <Col md={8}><p className='mb-0'>{pro.description}</p></Col>
                        <Col md={2}><h5 className='mb-0'>{this.checkQuantity(pro)} {pro.original_count ? pro.original_count : pro.count}</h5></Col>
                        <Col md={2}><h5 className='mb-0'>{pro.count}</h5></Col>
                    </Row>
                })
            }
        </div>
    }

    modalTraspasoSeleccionado(){
        const { showModal, traspaso_seleccionado } = this.state
        if(!traspaso_seleccionado) return false

        return <Modal show={showModal} onHide={()=>this.handleClose()} centered >
        <Modal.Header closeButton>
          <Modal.Title>Traspaso {traspaso_seleccionado.id} <span style={{ fontSize: 12 }}> {fechaATexto(traspaso_seleccionado.fecha)}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { this.mostrarProductosTraspaso(traspaso_seleccionado.productos) }                            
        </Modal.Body>
      </Modal>
    }

    seleccionarTraspas(traspaso_seleccionado) {
        this.setState({ traspaso_seleccionado, showModal: true })
    }

    showMeta(inv) {
        if(!inv.meta) return false
        if(typeof inv.meta !== "object") return false
        return <Card bg='dark' className='p-2 text-light'>
            <Row>
            <Col md={12}><h5 className='mb-0'><b>BODEGA</b> {inv.meta.nombre ? inv.meta.nombre : "SIN INFO."}</h5></Col>
            <Col md={4}><h5 className='mb-0'><b>FECHA</b> {inv.meta.fecha}</h5></Col>
            <Col md={4}><h5 className='mb-0'><b>DESDE</b> {inv.meta.desde}</h5></Col>
            <Col md={4}><h5 className='mb-0'><b>HORA</b> {inv.meta.hasta}</h5></Col>
        </Row>
        </Card>
    }

    mostrarTraspasosInventarioDefontana() {
        const { datos } = this.state
        if(!datos) return false
        if(!datos.inventarios) return false
        if(Array.isArray(datos.inventarios) !== true) return false
        if(datos.inventarios.length < 1) return false
        return <div>
            <h5>{datos.inventarios.length} Inventarios creados en la fecha seleccionada</h5>
            <Row>
            {
                datos.inventarios.map(inv => {
                    return <Col className='hover' md={6} key={inv._id} onClick={() => this.seleccionarTraspas(inv)}>
                        <Card className='p-3 mb-3' >
                        <h4>ID: {inv.id}</h4>
                        <h6>CREADA: {fechaATexto(inv.fecha)}</h6>
                        {this.showMeta(inv)}
                        { this.mostrarProductosTraspaso(inv.productos) }       
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    mostrarMovimientosInventario() {
        const { reportes_inventario, emitiendoDefontana } = this.state

        return <div>
            <h4>Traspasos de inventarios preparados</h4>
            <p>¡Atención! Recuerda que estos traspasos se preparan automáticamente al cargar esta página de acuerdo a la fecha seleccionada, no es un registro conectado en Defontana, y debes tener precaución de no emitir más de una vez un traspaso de inventario</p>
            <Row>
            {
                reportes_inventario.map((rep,i) => {
                    return <Col key={`mov-${rep.nombre}`} xs={6}>
                            <Card className='p-3'>
                                <h4>{rep.nombre}</h4>
                                {this.visualizarMovimientoBodegaProductos(rep.payload.details, i)}
                                { emitiendoDefontana ? <Spinner animation='border' /> : <Button size="sm" onClick={() => this.confirmarTraspaso(i)}>EMITIR TRASPASO DE INVENTARIO A DEFONTANA</Button> }
                            </Card>
                        </Col>
                })
            }
            </Row>
            
        </div>
    }

    mostrarUsuarios() {
        const { usuarios, datos, loadingUsuarios } = this.state
        if(loadingUsuarios) return <Col md={12}><PantallaCargando /></Col>
        if(!datos) return this.sinRegistros()
        if( typeof datos !== 'object' ) return this.sinRegistros()
        if( !datos.productos ) return this.sinRegistros()
        if( Array.isArray(datos.productos) !== true ) return this.sinRegistros()
        if( datos.productos.length < 1 ) return this.sinRegistros()

        return <div className='mt-4'>
            <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="activos" title="Productos">
                <ReactToPrint
                    trigger={() => {
                        return <Button size="sm" variant='success'>IMPRIMIR</Button>
                    }}
                    content={() => this.componentRef}
                    />
                        <BoxImprimir datos={datos} ref={el => (this.componentRef = el)} />
                </Tab>
                <Tab eventKey="archivados" title="Traspaso de inventario">
                    {this.mostrarMovimientosInventario()}
                </Tab>
                <Tab eventKey="inventarios" title="Traspasos bodegas defontana">
                    {this.mostrarTraspasosInventarioDefontana()}
                </Tab>
            </Tabs>
        </div>
    }
    
    render(){
        const { fecha } = this.state
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
      </div>
            <h4>Informe de ventas</h4>
            <Row>
                <Col md={3}>
                    <input className='form-control' type="date" defaultValue={fecha} onChange={this.handleChangeFecha} style={{ padding: "2px 10px !important" }} />
                </Col>
                <Col md={3}>
                    <input className='form-control' name="hora" type="time" onChange={this.handleChange} style={{ padding: "2px 10px !important" }} />
                </Col>
                <Col md={3}>
                    <input className='form-control' name="horahasta" type="time" onChange={this.handleChange} style={{ padding: "2px 10px !important" }} />
                </Col>
                <Col md={3}>
                    <button className='btn btn-primary' onClick={() => this.getUsuarios() }>BUSCAR</button>
                </Col>
            </Row>
            {this.modalTraspasoSeleccionado()}
          {this.mostrarUsuarios()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(InformeVentas);