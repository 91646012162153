import React from "react";
import { Col, Row } from "react-bootstrap";

export const ImprimirComanda = React.forwardRef((props, ref) => {
    const pedido_seleccionado = props.pedido

    const calcularPrecioFinal = (producto) => {
        let precio_final = producto.precio ? producto.precio : 0
        let cantidad = producto.cantidad ? producto.cantidad : 1
        precio_final = precio_final * cantidad
        // VER OPCIONES
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    precio_final = 0
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        const precio_opcion_seleccionada = producto.opciones[encontrar_seleccion].precio
                        if(isNaN(precio_opcion_seleccionada) !== true){
                            precio_final = precio_opcion_seleccionada * cantidad
                        }
                    }
                }
            }
        }
    
        // VER ADICIONALES
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            if(isNaN(opcion.precio) !== true){
                                precio_final = precio_final + opcion.precio      
                            }
                        }
                    }
                }
            }
        }
    
        return precio_final
    }

    let calcular_total = pedido_seleccionado.productos.reduce((prev, next) => prev + calcularPrecioFinal(next), 0)
    let total = calcular_total


    return (
      <div ref={ref} className="p-3">
        <h4 style={{ fontSize: 40 }}>PEDIDO {pedido_seleccionado.numero}</h4>
        <Row style={{ marginBottom: 50 }}>
            <Col xs={6}>
            <h6 style={{ fontSize: 45 }}>Cliente</h6>
            <h6 style={{ fontSize: 40 }}>{pedido_seleccionado.nombre}</h6>
            </Col>

            <Col xs={6}>
            <h6 style={{ fontSize: 45 }}>Móvil</h6>
            <h6 style={{ fontSize: 40 }}>{pedido_seleccionado.movil ? pedido_seleccionado.movil : 'No disponible'}</h6>
            </Col>

            <Col xs={6}>
            <h6 style={{ fontSize: 45 }}>Email</h6>
            <h6 style={{ fontSize: 40 }}>{pedido_seleccionado.email ? pedido_seleccionado.email : 'No disponible'}</h6>
            </Col>

            <Col xs={12} style={{ marginTop: 50 }}><hr /><h4>Ubicación</h4></Col>

            <Col xs={6}>
            <h6 style={{ fontSize: 45 }}>Región</h6>
            <h6 style={{ fontSize: 40 }}>{pedido_seleccionado.destino.region }</h6>
            </Col>

            <Col xs={6}>
            <h6 style={{ fontSize: 45 }}>Comuna</h6>
            <h6 style={{ fontSize: 40 }}>{pedido_seleccionado.destino.comuna}</h6>
            </Col>

            <Col xs={12}>
            <h6 style={{ fontSize: 45 }}>Dirección</h6>
            <h6 style={{ fontSize: 40 }}>{pedido_seleccionado.destino.direccion}</h6>

            <h6 style={{ fontSize: 45 }}>Casa · Oficina · Depto</h6>
            <h6 style={{ fontSize: 40 }}>{pedido_seleccionado.destino.numero}</h6>
            </Col>

        </Row>
        
            <Row style={{ marginBottom: 50 }}>
            <Col xs={12}><hr /><h4>Detalles</h4></Col>
                <Col xs={6}>
                    <p style={{ fontSize: 40, fontWeight: "bold" }} className='mb-0'>Método de envío</p>
                    <h6 style={{ fontSize: 45 }}>{pedido_seleccionado.metodo_envio}</h6>
                </Col>
                <Col xs={6}>
                    <p style={{ fontSize: 40, fontWeight: "bold" }} className='mb-0'>Fecha hora entrega</p>
                    <h6 className='mb-0' style={{ fontSize: 40 }}>{pedido_seleccionado.dia_programado} · {pedido_seleccionado.horario_entrega}</h6>
                </Col>
                <Col xs={12}>
                    <p style={{ fontSize: 40, fontWeight: "bold" }} className='mb-0'>Método de pago</p>
                    <h6 className='mb-0' style={{ fontSize: 40, fontWeight: "bold" }}>{pedido_seleccionado.tipo_pago}</h6>
                </Col>
                <Col md={12}><hr /></Col>
            </Row>
            <Row style={{ marginBottom: 50 }}>
                            <Col xs={8}>
                            <h6 style={{ fontSize: 45 }}>Subtotal</h6>
                            </Col>
                            <Col xs={4} className='text-right' style={{ fontSize: 40 }}>
                            ${Intl.NumberFormat("es-ES").format(calcular_total)}   
                            </Col>
                            <Col xs={8}>
                            <h6 style={{ fontSize: 45 }}>Delivery</h6>
                            </Col>
                            <Col xs={4} className='text-right' style={{ fontSize: 40 }}>
                            ${Intl.NumberFormat("es-ES").format(pedido_seleccionado.delivery)}   
                            </Col>
                            <Col xs={8}>
                            <h6 style={{ fontSize: 45 }}>Propina</h6>
                            </Col>
                            <Col xs={8}>
                            <h6 style={{ fontSize: 45 }}>Total</h6>
                            </Col>
                            <Col xs={4} className='text-right' style={{ fontSize: 40 }}>
                            ${Intl.NumberFormat("es-ES").format(pedido_seleccionado.subtotal)}   
                            </Col>
                            <Col xs={12}>
                            <hr />
                            <h6 style={{ fontSize: 45 }}>Productos</h6>
                            <Row style={{ marginBottom: 50 }}>
                                <Col xs={8}><p style={{ fontSize: 30, fontWeight: "bold" }} className='mb-0'>Producto</p></Col>
                                <Col xs={2}><p style={{ fontSize: 30, fontWeight: "bold" }} className='mb-0'>Cantidad</p></Col>
                                <Col xs={2}><p style={{ fontSize: 30, fontWeight: "bold" }} className='mb-0'>Total</p></Col>
                            </Row>
                            <hr className='hr' />
                            {
                                pedido_seleccionado.productos.map((pro,i) => {
                                    return <Row key={`pro-${i}`}>
                                        <Col xs={8}>
                                            <p className='mb-0' style={{ fontSize: 40, fontWeight: "bold" }}>{pro.titulo}</p>
                                        </Col>
                                        <Col xs={2}>
                                            <h5 className='mb-0 text-secondary' style={{ fontWeight:'bold', fontSize: 40 }}><b>{Intl.NumberFormat("es-ES").format(pro.cantidad)}</b></h5>
                                        </Col>
                                        <Col xs={2}>
                                            <h5 className='mb-0 text-secondary' style={{ fontWeight:'bold', fontSize: 40 }}>${Intl.NumberFormat("es-ES").format(pro.precio_final)}</h5>
                                        </Col>
                                        {pedido_seleccionado.productos.length - 1 === i ? false : <Col xs={12} ><hr /></Col> }
                                    </Row>
                                })
                            }
                            </Col>
                        </Row>
                                   
      </div>
    );
});