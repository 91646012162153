import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const ListaConductores = (props) => {

    const [ conductores, setConductores ] = useState(props.conductores)
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const options = []
    const propietario = "76069087"
    // const toaststyle = ESTILOTOAST.create(props.toast)

    const getConductores = () => {
        console.log(propietario)
        const url = `https://back.delpasystem.com/api/conductores/list-v2?propietario=${propietario}`;
        console.log(url)
        let options = []
        return fetch(url)
        .then(pros => pros.json())
        .then(pros => {
            const datos = pros ? pros : []
            datos.conductores.map(conductor => {
                options.push({ value: conductor._id, label: `${conductor.nombres} ${conductor.apellidos}` })
            }) 
            setConductores(options)
            setLoadingConductores(false)
        })
        .catch(error => {
            setLoadingConductores(false)
            // return toast.error('No se pudo realizar esta operación', this.state.toaststyle)
        })
    }

    const handleChangeConductor = (e) => {
        if(props.onChange) return props.onChange(e)
    }

    useEffect(() => {
        getConductores()
    }, [])

    return <div className='mt-3 mb-3'>
        <h4>{conductores.length} Conductores</h4>
        <Select 
                style={{ marginBottom: 10 }}
                onChange={handleChangeConductor}
                options={conductores}
                defaultValue={''}
                isLoading={loadingConductores}
                placeholder="Buscar conductor..."
              />
    </div>
}

export default ListaConductores