import { useEffect, useState } from "react"
import SelectorZona from "../../subComponents/general/selector_zona"
import { urlapi } from "../../lib/backend/data"
import { toast } from "react-toastify"
import { Accordion, Card, Col, Row, Spinner } from "react-bootstrap"
import EdicionTarifa from "./edicion_tarifa"
import { FaAngleDown, FaCircle } from "react-icons/fa6";

const { default: Header } = require("../Header")

const Envios = (props) => {
    const [ loading, setLoading ] = useState(true)
    const [ tarifas, setTarifas ] = useState([])
    const [ zonas, setZonas ] = useState([])
    const [ condicion, setCondicion ] = useState({ geofences: { $nin: [null] } })

    useEffect(() => {
        obtenerDatos()
    }, [])

    const obtenerDatos = async (cond) => {
        setLoading(true)
        const query = cond ? cond : condicion
        return fetch(`${urlapi}/tarifas/geocercas/list`, {
          method: 'POST',
          body: JSON.stringify({
            condicion: query
          }),
          headers: {
            'Content-Type': 'application/json'
          },
        })
          .then((res) => {
            if (res.status === 401) return window.location = "/login"
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setTarifas(res)
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const onChangeData = (data) => {
        const i = tarifas.findIndex(ta => ta._id === data._id)
        console.log({i})
        if(i < 0) return false
        return setTarifas(prev => {
          let actual = [...prev]
          actual[i] = data
          return [...[], ...actual]
        })
      }

      const mostrarTarifas = () => {
        if(loading) return <Spinner />
        return <div>
            <Accordion >
            {
                tarifas.map((tarifa,ir) => {

                    return <Card key={`tar-${ir}`} className="p-0" style={{ background: "#e2e2e2"}} >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`tar-${ir}`}>
                        <div className="hover pl-3 pr-3 pb-0">
                            <h4 className="mb-0">{tarifa.title} {tarifa.closed === true ? <FaCircle color="red" /> : <FaCircle color="green"  />}</h4>
                            <p className="mb-0"><FaAngleDown /> Toca para ver más información</p>
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`tar-${ir}`}>
                            <div className="p-3">
                            <EdicionTarifa zonas={zonas} registro={tarifa} onChange={(data) => onChangeData(data)} />
                            </div>
                        </Accordion.Collapse>
                    </Card>
                })
            }
            </Accordion>
        </div>
      } 

    return <div className="fluid">
    <Header/>   
    <div className="container-fluid">
        <div className="row">
        <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Envíos</h1>
        </div>
            {mostrarTarifas()}
        </main>
        </div>
        </div>
 </div>
}

export default Envios