import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Navbar, Container } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import { key_local_storage_user } from '../../lib/backend/data'
import { logotipo } from '../../lib/global/data'

class Header extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    render(){
        const { user } = this.state
      return <Navbar bg="dark" expand="lg" className='shadow' >
      <Container fluid>
        <Navbar.Brand className='mr-3'><img alt="logo" id="logo-principal" src={logotipo}  /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav >
          <Link to="/" className="nav-link text-white">Inicio</Link>
          <Link to="/establecimientos" className="nav-link text-white">Establecimientos</Link>
          <Link to="/informe-ventas" className="nav-link text-white">Informe de ventas</Link>
          <Link to="/envios" className="nav-link text-white">Despacho</Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
            <Dropdown>
            <Dropdown.Toggle variant="white" className='text-white' id="dropdown-basic">
            <i className="fas fa-user-circle"></i> {user.data.nombres}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item eventKey="1"><Link to="/login" className="text-danger"><i class="fas fa-power-off"></i> Salir</Link></Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
            </Navbar.Collapse>
      </Container>
    </Navbar>
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Header);