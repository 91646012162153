import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Modal, Badge } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es';
import data from '../../lib/backend/data'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import socket from '../../lib/websockets'
import { showByEstadoPago, showStringByTipoPago } from '../../lib/helpers/helpers'
import { opcionEscogidaSimple } from '../../lib/helpers/pedidos/pedidos'
import { key_local_storage_user } from '../../lib/backend/data'
import { formatDateHoy } from '../../lib/helpers/dates'
import ReactToPrint from 'react-to-print'
import { ImprimirComanda } from './imprimir_comanda'
import ListaConductores from '../../subComponents/conductores/list'

const notification = new Audio('https://bumer.cl/sound.mp3')

const pageStyle = `
@media print {
  h4, div, h6 {
    font-size: 45px !important;
  }
  p {
    font-size: 30px !important;
  }
}  
`;
class CentroPedidos extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_establecimiento: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            pedidos: [],
            establecimientos: [],
            establecimiento_seleccionado: false,
            tipo_visualizacion: 'pendientes',
            pedido_seleccionado: false,
            modal_pedido_seleccionado: false,
            loading_accion_pedido: false,
            tipo_visualizacion_pedido: 'resumen',
            status_conection: true,
            fecha: formatDateHoy(new Date()),
            conductor_seleccionado: false,
            asignando: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFecha = this.handleChangeFecha.bind(this)
    }

    handleChangeFecha(e){
        const { name, value } = e.target
        const { id_establecimiento } = this.state
        this.getPedidos(id_establecimiento, value)
        return this.setState({ fecha: value })
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    conexionInternet(){
        window.addEventListener("load", () => {
            // 1st, we set the correct status when the page loads
            navigator.onLine
              ? this.setState({ status_conection: true })
              : this.setState({ status_conection: false })
        
            // now we listen for network status changes
            window.addEventListener("online", () => {
                const { id_establecimiento, fecha } = this.state
                this.getPedidos(id_establecimiento, fecha)
                this.setState({ status_conection: true })
            });
        
            window.addEventListener("offline", () => {
                this.setState({ status_conection: false })
            });
          });
    }

    componentDidMount(){
        const { id_establecimiento } = this.state
        const fecha = formatDateHoy(new Date())
        this.getPedidos(id_establecimiento, fecha)
        this.conexionInternet()
    }

    websocketsFunciones(establecimiento_seleccionado){
        console.log('registrado socket')
        socket.on(establecimiento_seleccionado, async pedido => {
            const { pedidos, fecha } = this.state
            if(fecha === pedido.dia_programado){
                toast.info('Tienes un nuevo pedido para hoy')
                notification.play()
                pedidos.push(pedido)
                return this.setState({ pedidos })
            }
            return
        })
        socket.on('cambio_status', payload => {
            const { pedido_seleccionado } = this.state
            if(pedido_seleccionado._id === payload.id){
                this.setState({ modal_pedido_seleccionado: false, pedido_seleccionado: false })
                toast.info('Alguien más ejecutó una acción sobre esta pedido')
            }
            return
        })
        socket.on('cambio_status_confirmado', payload => {
            const { pedidos, pedido_seleccionado } = this.state
            const i = pedidos.findIndex(pe => pe._id === payload.id)
            if(i > -1){
                pedidos[i].status = payload.status
                pedidos[i].usuario_identificador = payload.usuario_identificador
                this.setState({ pedidos })
            }
            if(pedido_seleccionado._id === payload.id){
                pedido_seleccionado.status = payload.status
                pedido_seleccionado.usuario_identificador = payload.usuario_identificador
                return this.setState({ pedido_seleccionado })
            }
        })
    }

    getPedidos(idestablecimiento,fecha){
        const { user } = this.state
        this.setState({ loadingPedidos: true })
        return fetch(`${data.urlapi}/pedidos/list?idestablecimiento=${idestablecimiento}&fecha=${fecha}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            this.websocketsFunciones(`${idestablecimiento}_pedidos`)
            const pedidos = res.sort((a,b)=>{
                if(a.createdAt > b.createdAt )  return 1
                if(a.createdAt < b.createdAt )  return -1
                return 0
            })
            this.setState({ loadingPedidos: false, pedidos })
        })
        .catch(error => {
            this.setState({ loadingPedidos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleClose(){
        return this.setState({ modal_pedido_seleccionado: false, tipo_visualizacion_pedido: 'resumen' })
    }

    seleccionarConductor(conductor_seleccionado) {
        console.log(conductor_seleccionado)
        return this.setState({ conductor_seleccionado })
    }

    showByVisualizacionPedido(pedido_seleccionado, total, propina, calcular_total){
        const { tipo_visualizacion_pedido, conductor_seleccionado, asignando } = this.state

        if(tipo_visualizacion_pedido === 'datos_cliente') return <div>
            <Row>
                <Col xs={12}><h4>Cliente</h4></Col>
            
                <Col xs={6}>
                <h6>Cliente</h6>
                <h6 style={{ fontWeight: 'lighter' }}>{pedido_seleccionado.nombre}</h6>
                </Col>

                <Col xs={6}>
                <h6>Móvil</h6>
                <h6 style={{ fontWeight: 'lighter' }}>{pedido_seleccionado.movil ? pedido_seleccionado.movil : 'No disponible'}</h6>
                </Col>

                <Col xs={6}>
                <h6>Email</h6>
                <h6 style={{ fontWeight: 'lighter' }}>{pedido_seleccionado.email ? pedido_seleccionado.email : 'No disponible'}</h6>
                </Col>

                <Col xs={12}><hr /><h4>Ubicación</h4></Col>

                <Col xs={6}>
                <h6>Región</h6>
                <h6 style={{ fontWeight: 'lighter' }}>{pedido_seleccionado.destino.region }</h6>
                </Col>

                <Col xs={6}>
                <h6>Comuna</h6>
                <h6 style={{ fontWeight: 'lighter' }}>{pedido_seleccionado.destino.comuna}</h6>
                </Col>

                <Col xs={12}>
                <h6>Dirección</h6>
                <h6 style={{ fontWeight: 'lighter' }}>{pedido_seleccionado.destino.direccion}</h6>

                <h6>Casa · Oficina · Depto</h6>
                <h6 style={{ fontWeight: 'lighter' }}>{pedido_seleccionado.destino.numero}</h6>
                </Col>

            </Row>
        </div>

        if(tipo_visualizacion_pedido === 'resumen') return <div>
            
            <Row>
                <Col xs={6}>
                    <p className='mb-0'>Método de envío</p>
                    <h6>{pedido_seleccionado.metodo_envio}</h6>
                </Col>
                <Col xs={6}>
                    <p className='mb-0'>Fecha hora entrega</p>
                    <h6 className='mb-0'>{pedido_seleccionado.dia_programado} · {pedido_seleccionado.horario_entrega}</h6>
                </Col>
                <Col xs={12}>
                    <p className='mb-0'>Método de pago</p>
                    <h6 className='mb-0'>{pedido_seleccionado.tipo_pago}</h6>
                </Col>
                <Col md={12}><hr /></Col>
            </Row>
            <Row>
                            <Col xs={8}>
                            <h6>Subtotal</h6>
                            </Col>
                            <Col xs={4} className='text-right'>
                            ${Intl.NumberFormat("es-ES").format(calcular_total)}   
                            </Col>
                            <Col xs={8}>
                            <h6>Delivery</h6>
                            </Col>
                            <Col xs={4} className='text-right'>
                            ${Intl.NumberFormat("es-ES").format(pedido_seleccionado.delivery)}   
                            </Col>
                            <Col xs={8}>
                            <h6>Propina</h6>
                            </Col>
                            <Col xs={8}>
                            <h6>Total</h6>
                            </Col>
                            <Col xs={4} className='text-right'>
                            ${Intl.NumberFormat("es-ES").format(pedido_seleccionado.subtotal)}   
                            </Col>
                            <Col xs={12}>
                            <hr />
                            <h6>Productos</h6>
                            </Col>
                        </Row>
                                    <hr className='hr' />
                                    <Row>
                                        <Col xs={3}><p className='mb-0'>Imagen</p></Col>
                                        <Col xs={5}><p className='mb-0'>Producto</p></Col>
                                        <Col xs={2} className="text-right"><p className='mb-0'>Cantidad</p></Col>
                                        <Col xs={2} className="text-right"><p className='mb-0'>Total</p></Col>
                                    </Row>
                                    <hr className='hr' />
                            {
                                pedido_seleccionado.productos.map((pro,i) => {
                                    return <Row key={`pro-${i}`}>
                                        <Col xs={3}>
                                            { pro.imagen ? <img src={pro.imagen} style={{ width: '100%' }} /> : false }
                                            
                                        </Col>
                                        <Col xs={5}>
                                            <p className='mb-0' style={{ fontSize: 12 }}>{pro.titulo}</p>
                                        </Col>
                                        <Col xs={2}>
                                            <h5 className='mb-0 text-secondary' style={{ fontWeight:'lighter' }}><b>{Intl.NumberFormat("es-ES").format(pro.cantidad)}</b></h5>
                                        </Col>
                                        <Col xs={2} className="text-right">
                                            <h5 className='mb-0 text-secondary' style={{ fontWeight:'lighter' }}>${Intl.NumberFormat("es-ES").format(pro.precio_final)}</h5>
                                        </Col>
                                        {pedido_seleccionado.productos.length - 1 === i ? false : <Col xs={12} ><hr /></Col> }
                                    </Row>
                                })
                            }
                            
                        <hr />
                        {this.accionesPedido(pedido_seleccionado)}
        </div>

        if(tipo_visualizacion_pedido==='imprimir') return <div>

        <ReactToPrint
          trigger={() => {
            return <Button size="sm" variant='success'>IMPRIMIR</Button>
          }}
          content={() => this.componentRef}
          //pageStyle={pageStyle}
        />
        <ImprimirComanda pedido={pedido_seleccionado} ref={el => (this.componentRef = el)} />
                            
                        <hr />
                        {this.accionesPedido(pedido_seleccionado)}
    
    </div>

    }

    imprimir(){
        return this.setState({ tipo_visualizacion_pedido: 'imprimir' })
    }

    modalPedidoSeleccionado(){
        const { modal_pedido_seleccionado, pedido_seleccionado } = this.state
        if(!pedido_seleccionado) return false
        let calcular_total = pedido_seleccionado.productos.reduce((prev, next) => prev + this.calcularPrecioFinal(next), 0)

        let total = calcular_total

        const propina = pedido_seleccionado.propina ? pedido_seleccionado.propina : 0
        if(propina) total = total + propina


        return <Modal show={modal_pedido_seleccionado} onHide={()=>this.handleClose()} centered >
        <Modal.Header closeButton>
          <Modal.Title>Pedido {pedido_seleccionado.numero} <span style={{ fontSize: 12 }}><Moment locale="ES" fromNow>{pedido_seleccionado.createdAt}</Moment></span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h6>Última actualización hecha por: <b style={{ color: "#007bff" }}>{pedido_seleccionado.usuario_identificador ? pedido_seleccionado.usuario_identificador : 'Nadie ha actualizado aún este pedido'}</b> <hr/></h6>
            <Row>
                <Col xs={4} className="text-center hover" onClick={()=>this.setState({ tipo_visualizacion_pedido: 'resumen' })} ><small>Resumen</small></Col>
                <Col xs={4} className="text-center hover" onClick={()=>this.setState({ tipo_visualizacion_pedido: 'datos_cliente' })} ><small>Datos cliente</small></Col>
                <Col xs={4} className="text-center hover" onClick={()=>this.setState({ tipo_visualizacion_pedido: 'imprimir' })} ><small>Imprimir</small></Col>
                <Col xs={12}><hr /></Col>
            </Row>
            {this.showByVisualizacionPedido(pedido_seleccionado, total, propina, calcular_total)}
                            
        </Modal.Body>
      </Modal>
    }

    asignarConductor(id){
        const { conductor_seleccionado, pedidos, pedido_seleccionado } = this.state
        const loading = "asignando"
        this.setState({ [loading]: true })
        socket.emit('cambio_status', { id, status: 3 })
        return fetch(`${data.urlapi}/pedidos/asignarconductor`,{
            method:'POST',
            body: JSON.stringify( {
                id,
                conductor: conductor_seleccionado,
            } ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("sin respuesta del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                const i = pedidos.findIndex(pe => pe._id === id)
                if(i > -1){
                    pedidos[i].conductor_designado = conductor_seleccionado
                    socket.emit('cambio_conductor_designado', { id, conductor_designado: conductor_seleccionado })
                    this.setState({ modal_pedido_seleccionado: false })
                }
                toast.success('Realizado exitosamente')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }


    cambiarEstadoPedido(id,status){
        const { pedidos, pedido_seleccionado, user } = this.state
        if(status === 3){
            if(!pedido_seleccionado.conductor_designado){
                return toast.error("Debes asignar primero un conductor")
            }
        }
        this.setState({ loading_accion_pedido: true })
        socket.emit('cambio_status', { id, status })
        return fetch(`${data.urlapi}/pedidos/cambiarstatus`,{
            method:'POST',
            body: JSON.stringify( {
                id,
                status,
                usuario_identificador: user.data.nombres
            } ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("sin respuesta del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                const i = pedidos.findIndex(pe => pe._id === id)
                if(i > -1){
                    pedidos[i].status = status
                    pedidos[i].usuario_identificador = user.data.nombres
                    socket.emit('cambio_status_confirmado', { id, status, usuario_identificador: user.data.nombres })
                    this.setState({ modal_pedido_seleccionado: false })
                }
                toast.success('Realizado exitosamente')
            }
            this.setState({ loading_accion_pedido: false })
        })
        .catch(error => {
            this.setState({ loading_accion_pedido: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    boxConductorSeleccionado(){
        const { pedido_seleccionado } = this.state
        if(!pedido_seleccionado ) return false
        return <div>
            {
                pedido_seleccionado .conductor_designado ? <div>
                    <Card className='p-3'>
                        <p className='mb-0'>Conductor designado</p>
                        <h4 className='mb-0'>{pedido_seleccionado .conductor_designado.label}</h4>
                    </Card>
                </div> : false
            }
        </div>
    }

    accionesPedido(pedido){
        const { loading_accion_pedido, conductor_seleccionado, asignando } = this.state
        if(loading_accion_pedido) return <Spinner animation='border' />
        if(pedido.status===0) return <Row>
            <Col xs={6}><Button variant="outline-danger" style={{ width: '100%' }} onClick={()=>this.cambiarEstadoPedido(pedido._id, 10)}>RECHAZAR</Button></Col>
            <Col xs={6}><Button variant="primary" style={{ width: '100%' }} onClick={()=>this.cambiarEstadoPedido(pedido._id, 1)} >CONFIRMAR</Button></Col>
        </Row>

        if(pedido.status===1) return <Row>
        <Col xs={6}><Button variant="outline-danger" style={{ width: '100%' }} onClick={()=>this.cambiarEstadoPedido(pedido._id, 10)}>RECHAZAR</Button></Col>
        <Col xs={6}><Button variant="primary" style={{ width: '100%' }} onClick={()=>this.cambiarEstadoPedido(pedido._id, 2)} >LISTO PARA RECOLECCIÓN</Button></Col>
        </Row>

        if(pedido.status===2) return <Row>
            <Col xs={12}>
            {this.boxConductorSeleccionado()}

            <ListaConductores onChange={driver => this.seleccionarConductor(driver)} conductores={[]} />
            {
                conductor_seleccionado !== false ? <div>
                    {
                        asignando ? <Spinner animation='grow' /> : <Button variant="outline-primary" className='mb-3' style={{ width: "100%" }} onClick={() => this.asignarConductor(pedido._id)}>ASIGNAR CONDUCTOR</Button>
                    }
                </div> : false
            }
            </Col>
        <Col xs={6}><Button variant="outline-danger" style={{ width: '100%' }} onClick={()=>this.cambiarEstadoPedido(pedido._id, 10)}>RECHAZAR</Button></Col>
        
        <Col xs={6}><Button variant="primary" style={{ width: '100%' }} onClick={()=>this.cambiarEstadoPedido(pedido._id, 3)} >ENVIADO</Button></Col>
        </Row>

        return <div>
            {this.boxConductorSeleccionado()}
            <ListaConductores onChange={driver => this.seleccionarConductor(driver)} conductores={[]} />
            {
                conductor_seleccionado !== false ? <div>
                    {
                        asignando ? <Spinner animation='grow' /> : <Button variant="outline-primary" className='mb-3' style={{ width: "100%" }} onClick={() => this.asignarConductor(pedido._id)}>ASIGNAR CONDUCTOR</Button>
                    }
                </div> : false
            }
        </div>
    }

    mostrarOpcionEscogida(producto){
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        return <p className='mb-0'>{producto.opciones[encontrar_seleccion].titulo}</p>
                    }
                }
            }
        }
        return false
    }

    visualizarAdicionales(producto){
        let escogidos = []
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? escogidos.map((item,i) => <span className='spanguia mr-2' key={`item-${i}`}>{item}</span>) : false
    }

    calcularPrecioFinal(producto){
        let precio_final = producto.precio ? producto.precio : 0
        let cantidad = producto.cantidad ? producto.cantidad : 1
        precio_final = precio_final * cantidad
        // VER OPCIONES
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    precio_final = 0
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        const precio_opcion_seleccionada = producto.opciones[encontrar_seleccion].precio
                        if(isNaN(precio_opcion_seleccionada) !== true){
                            precio_final = precio_opcion_seleccionada * cantidad
                        }
                    }
                }
            }
        }
    
        // VER ADICIONALES
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            if(isNaN(opcion.precio) !== true){
                                precio_final = precio_final + opcion.precio      
                            }
                        }
                    }
                }
            }
        }
    
        return precio_final
    }

    ifTipoPagoChecked(tipopago, pedido){
        if(pedido.tipo_pago === tipopago) return <i className="text-success fas fa-check-square"></i>
        return <i className="far fa-square"></i>
    }

    contadorIcon(array){
        if(array.length < 1) return false
        return <Badge className='bg-danger text-light' >{array.length}</Badge>
    }

    opcionesPedidos(){
        const { pedidos, tipo_visualizacion } = this.state
        return <Row>
            <Col xs={3} className="text-center">
                <h6 className={`hover ${tipo_visualizacion==='pendientes'?'text-primary':''}`} onClick={()=>this.setState({ tipo_visualizacion: 'pendientes' })}>Pendientes {this.contadorIcon(pedidos.filter(pe => pe.status === 0))}</h6>
            </Col>
            <Col xs={3} className="text-center">
                <h6 className={`hover ${tipo_visualizacion==='en-preparacion'?'text-primary':''}`} onClick={()=>this.setState({ tipo_visualizacion: 'en-preparacion' })}>En preparación {this.contadorIcon(pedidos.filter(pe => pe.status === 1))}</h6>
            </Col>
            <Col xs={3} className="text-center">
                <h6 className={`hover ${tipo_visualizacion==='listos-para-recoleccion'?'text-primary':''}`} onClick={()=>this.setState({ tipo_visualizacion: 'listos-para-recoleccion' })}>Listos para recolección {this.contadorIcon(pedidos.filter(pe => pe.status === 2))}</h6>
            </Col>
            <Col xs={3} className="text-center">
                <h6 className={`hover ${tipo_visualizacion==='historial'?'text-primary':''}`} onClick={()=>this.setState({ tipo_visualizacion: 'historial' })}>Historial</h6>
            </Col>
            <Col xs={12}><hr /></Col>
        </Row>
    }
    
    showByTipoVisualizacion(){
        const { tipo_visualizacion, pedidos } = this.state
        if(tipo_visualizacion==='pendientes') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===0),'pendientes')
        if(tipo_visualizacion==='en-preparacion') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===1), 'en-preparacion')
        if(tipo_visualizacion==='listos-para-recoleccion') return this.visualizarPedidos(pedidos.filter(pe=>pe.status===2), 'listos-para-recoleccion')
        if(tipo_visualizacion==='historial') return this.visualizarPedidos(pedidos.filter(pe=>pe.status!==0&&pe.status!==1&&pe.status!==2 ), 'historial')
    }

    bgByTipo(tipo){
        if(tipo==='pendientes') return { bg: 'danger', text:'text-light'}
        return { bg: false, text: false }
    }

    visualizarPedidos(ordenes,tipo){

        const pedidos = ordenes.sort((a,b) => a.horario_entrega > b.horario_entrega ? 1 : -1 )
        if(pedidos.length < 1) return <div >
            <h3>Sin pedidos</h3>
            <p>No tienes pedidos en esta sección</p>
        </div>
        
        return <Row>
            <Col xs={12}>
                <h4 className='mb-4'>{pedidos.length} Pedidos</h4>
            </Col>
            {
                pedidos.map((pedido,i) => {                    
                    return <Col xs={12} className="mb-3 hover" key={`pedido-${i}`} onClick={()=>this.setState({ pedido_seleccionado: pedido, modal_pedido_seleccionado: true, conductor_seleccionado: false })}>
                        <Card bg={this.bgByTipo(tipo).bg} className={this.bgByTipo(tipo).text}>
                            <Card.Body>
                            <Row>
                            <Col xs={2}><h6 className='mb-0'><Moment locale="ES" fromNow>{pedido.createdAt}</Moment></h6></Col>
                            <Col xs={2}><h6 className='mb-0'>{pedido.metodo_envio}</h6></Col>
                            <Col xs={3}><h6 className='mb-0'>{pedido.dia_programado} · {pedido.horario_entrega}</h6></Col>
                            <Col xs={2}><h6 className='mb-0'>Pedido {pedido.numero}</h6><p className='mb-0'>Op.: {pedido.usuario_identificador}</p></Col>
                            <Col xs={2}><h6 className='mb-0'>{pedido.nombre}</h6></Col>
                            </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                })
            }
        </Row>
    }

    mostrarPedidos(){
        const { loadingPedidos, status_conection, fecha } = this.state

        if(!status_conection) return <h4>Debes estar conectado a internet para gestionar pedidos</h4>
        if(loadingPedidos){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }
        return <Card style={{ width: '100%' }}>
        <Card.Body>
            <label className='form-control-label d-block'>Fecha de consulta de órdenes</label>
            <input type="date" className='form-control mb-4' defaultValue={fecha} onChange={this.handleChangeFecha} />
            {this.opcionesPedidos()}
            {this.showByTipoVisualizacion()}
        </Card.Body>
        </Card>

    }

    mostrarEstablecimientos(){
        const { loadingEmails, establecimientos } = this.state

        if(loadingEmails){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        return <Card style={{ width: '100%' }} className="mb-3">
        <Card.Body>
        

          <Row>

            <Col md={3}>
            <label className='form-control-label'>Establecimiento</label>
            <select className='form-control' >
                {
                    establecimientos.map((est,i) => {
                        return <option key={`op-${i}`} value={est._id}>{est.titulo}</option>
                    })
                }
            </select>
            </Col>          
          </Row>          

          

        </Card.Body>
      </Card>
    }

    eliminarLista(id){
        const { listas } = this.state
        this.setState({ eliminando: true })
        return fetch(`${data.urlapi}/api/listas/delete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = listas.findIndex(li => li._id === id)
            if(i > -1){
                listas.splice(i,1)
                toast.success('Eliminada exitosamente', this.state.toaststyle)
                this.setState({ listas })
            }
            this.setState({ eliminando: false })
        })
        .catch(error => {
            console.log(error)
            this.setState({ eliminando: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    mostrarDatosListas(datos, filtro, input){
        const { eliminando } = this.state
        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = datos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <div>
<h4>{datos.length} Planes</h4>
<p>Elige los planes que desees</p>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{listasFiltradas.length} Coincicencias</label></Col>:false}
<Row>
{ listasFiltradas.map((lista,i)=>{
        return <Col md={3} key={`user${i}`}>
            <Card >
            <Card.Img variant="top" src={lista.image} />
        <Card.Body>
            <h1 className="mb-0">{lista.title}</h1>
            <p className="mb-0">{lista.description}</p>
            <h3 >CLP ${Intl.NumberFormat("es-ES").format(lista.price)} <p className="mb-0" style={{ fontSize: 13, display: 'inline-block' }}>{lista.frecuency}</p></h3> 
            { eliminando ? <Spinner animation="border" /> : this.botonSeleccionar(lista._id) }
            <hr />
            <h6 className="mb-0">Características</h6>
            </Card.Body>
            <ListGroup variant="flush">
                {
                    lista.beneficios.map(ben => {
                        return <ListGroup.Item><span className="mb-0" style={{ fontSize: 12 }}>{ben.title}</span></ListGroup.Item>
                    })
                }
            </ListGroup>
        </Card>
        </Col>
    }) }
    </Row>
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }

    displayStatusConection(){
        const { status_conection } = this.state
        if(status_conection === true) return <h6><i className="text-success fas fa-circle"></i> Conectado</h6>
        return <h6><i className="text-danger fas fa-circle"></i> Sin conexión a internet</h6>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <Link to={`/establecimientos`} className='text-dark' ><i className="fas fa-chevron-left"></i> Volver</Link>
      </div>
      <h1 className="h2"> Centro de pedidos</h1>
      {this.displayStatusConection()}
      {this.modalPedidoSeleccionado()}
      {this.mostrarPedidos()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(CentroPedidos);