import { DateTime } from "luxon";
import { useState } from "react"
import { Accordion, Badge, Button, Card, Col, Form, Row } from "react-bootstrap"
import { FaCircle, FaRegClock } from "react-icons/fa6"
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { TiDelete } from "react-icons/ti";
import { urlapi } from "../../lib/backend/data";
import { toast } from "react-toastify";
import SelectorZona from "../../subComponents/general/selector_zona";

const segmentoHorarioEjemplo = '[{"weekday":1,"from":"10:00","to":"19:00","days_out_of_time":2,"days_in_time":1},{"weekday":2,"from":"10:00","to":"19:00","days_out_of_time":2,"days_in_time":1},{"weekday":3,"from":"10:00","to":"19:00","days_out_of_time":2,"days_in_time":1},{"weekday":4,"from":"10:00","to":"19:00","days_out_of_time":1,"days_in_time":1},{"weekday":5,"from":"10:00","to":"19:00","days_out_of_time":1,"days_in_time":1},{"weekday":6,"from":"10:00","to":"15:00","days_out_of_time":2,"days_in_time":2},{"weekday":7,"from":"10:00","to":"15:00","days_out_of_time":1,"days_in_time":1}]'

const EdicionTarifa = (props) => {
    const {
        registro,
        zonas
    } = props
    const [ tarifa, setTarifa ] = useState(registro)
    const [ loading, setLoading ] = useState(false)

    const handleChangePriceSegment = (e,o) => {
        console.log({ o })
        const { name, value } = e.target
        return setTarifa(prev => {
            let actual = {...prev}
            actual.price_segments[o][name] = value
            return {...{}, ...actual}
        })
    }
    const onChangeGeofences = (geofences) => {
        return setTarifa(prev => {
            let actual = {...prev}
            actual.geofences = geofences
            return {...{}, ...actual}
        })
    }
    
    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${urlapi}/tarifas/geocercas/geotarifas`, {
          method: 'PUT',
          body: JSON.stringify(tarifa),
          headers: {
            'Content-Type': 'application/json'
          },
        })
          .then((res) => {
            if (res.status === 401) return window.location = "/login"
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }
    
    const handleChangeDeliverySegment = (e,o) => {
        console.log({ o })
        const { name, value } = e.target
        return setTarifa(prev => {
            let actual = {...prev}
            actual.delivery_segments[o][name] = value
            return {...{}, ...actual}
        })
    }

    const numeroADia = numero => {
        const dias = ["", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado","Domingo"];
        return dias[numero];
    };

    const eliminar = (pos) => {
        return setTarifa(prev => {
            let actual = {...prev}
            actual.price_segments.splice(pos,1)
            return {...{}, ...actual}
        })
    }

    const agregarSegmentoPrecio = () => {
        return setTarifa(prev => {
            let actual = {...prev}
            if(!actual.price_segments) actual.price_segments = []
            actual.price_segments.unshift({
                price_from: 0,
                price_to:0,

            }) 
            return {...{}, ...actual}

        })
    }
    const mostrarSegmentos = () => {
        if(!tarifa.price_segments) return false
        if(Array.isArray(tarifa.price_segments) !== true) return false
        return <div>
            <Button size="sm" className="mb-3 mt-2" variant="outline-primary" onClick={() => agregarSegmentoPrecio()} >AGREGAR SEGMENTO</Button>
            {
                tarifa.price_segments.map((price,pos) => {
                    return <div>
                        <Card className="p-2 mb-2 border shadow">
                        <Row>
                            <Col xs={4} >
                                <label className="form-control-label">Monto del carrito desde</label>
                                <input className="form-control" type="number" min="0" name="price_from" value={price.price_from} onChange={(e) => handleChangePriceSegment(e, pos)} />
                            </Col>
                            <Col xs={4} >
                                <label className="form-control-label">Monto del carrito hasta</label>
                                <input className="form-control" type="number" min="0" name="price_to" value={price.price_to} onChange={(e) => handleChangePriceSegment(e, pos)} />
                            </Col>
                            <Col xs={4} >
                                <label className="form-control-label">Tarifa del envío</label>
                                <input className="form-control" type="number" min="0" name="rate" value={price.rate} onChange={(e) => handleChangePriceSegment(e, pos)} />
                            </Col>
                        </Row>
                        <Button size="sm"  variant="text" className="text-danger mt-2" onClick={() => eliminar(pos)}><TiDelete size={25} style={{ verticalAlign: "middle" }} /> ELIMINAR</Button>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const handleCheck = (e) => {
        const valor = e.target.checked
        console.log({ valor })
        return setTarifa(prev => {
            let actual = {...prev}
            actual.closed = valor
            console.log(actual)
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }
    
    const mostrarSegmentosSemana = () => {
        if(!tarifa.delivery_segments) return false
        if(Array.isArray(tarifa.delivery_segments) !== true) return false
        return <div>
            {tarifa.delivery_segments.length < 1 ? <div>
                <h5>Sin registros</h5>
                <Button size="sm" onClick={() => {
                    const segmentos = JSON.parse(segmentoHorarioEjemplo)
                    setTarifa(prev => {
                        let actual = {...prev}
                        actual.delivery_segments = segmentos
                        return {...{}, ...actual}
                    })
                }} >GENERAR SEGMENTOS DE HORARIO</Button>
            </div> : false }
            <Row>
            {
                tarifa.delivery_segments.map((price,pos) => {
                    return <Col md={6}>
                        <Card className="p-2 mb-4 border shadow">
                            <h4>{numeroADia(price.weekday)}</h4>
                        <Row>
                            <Col xs={6} >
                                <label className="form-control-label">Desde</label>
                                <input className="form-control" type="time" name="from" value={price.from} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                            <Col xs={6} >
                                <label className="form-control-label">Hasta</label>
                                <input className="form-control" type="time" name="to" value={price.to} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                            <Col xs={6} >
                                <label className="form-control-label">Días tránsito dentro del horario</label>
                                <input className="form-control" type="number" name="days_in_time" value={price.days_in_time} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                            <Col xs={6} >
                                <label className="form-control-label">Días de tránsito fuera del horario</label>
                                <input className="form-control" type="number" name="days_out_of_time" value={price.days_out_of_time} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                        </Row>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const tiempoATextoEntendible = (time, hoy) => {
        if(time > 1){
            const eta = hoy.plus({ day: time }).setLocale('es').toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' });
            return `en ${time} días · ${eta}`
        }
        const eta_hoy = hoy.plus({ day: time }).setLocale('es').toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' })
        return `Mañana ${eta_hoy}`
    }
    

    const mostrarTarifaActual = () => {
        const fecha     = DateTime.now().setZone('America/Santiago')
        const tiempo    = fecha.toSQLTime()

        let texto_      = 'Para visualizar la vista previa debe tener horarios configurados'
        if(tarifa.delivery_segments){
            if(tarifa.delivery_segments.length > 0){
                const encontrar_segmento = tarifa.delivery_segments.findIndex(t => t.weekday === fecha.weekday)
                console.log({ encontrar_segmento, encontrar_segmento, id: tarifa._id.toString() })
                if(encontrar_segmento > -1){
                    let segmento    = tarifa.delivery_segments[encontrar_segmento]
                    if(tiempo >= segmento.from && tiempo <= segmento.to){
                        // EN TIEMPO
                        texto_ = `Llega ${tiempoATextoEntendible(segmento.days_in_time, fecha)}`
                    } else {
                        texto_ = `Llega ${tiempoATextoEntendible(segmento.days_out_of_time, fecha)}`
                    }
                }
            }
        }
            
        return texto_
    }

    const buscar_zonas = Array.isArray(tarifa.geofences) ? tarifa.geofences : []

    return <div>
        <label className="form-control-label bold">Título del método de envío</label>
        <input className="form-control" placeholder="Título" value={tarifa.title} />
        {/* {zonas.filter(zo => buscar_zonas.includes(zo._id)).map(zo => <Badge variant="dark" style={{ textTransform: "uppercase" }} className="mr-1 mb-1 mt-1">{zo.name}</Badge>)} */}
        <Form.Group className="mb-3 mt-3" controlId={tarifa._id}>
                                    <Form.Check type="switch" label="Deshabilitar este método de envío" defaultChecked={tarifa.closed === true} onChange={handleCheck} />
        </Form.Group>
        <SelectorZona hideButton={true} onChange={(data) => onChangeGeofences(data)} defaultValue={buscar_zonas} />
        <Button variant="success" className="mt-3" onClick={() => guardarCambios()} disabled={loading} > {loading ? "GUARDANDO..." : "GUARDAR"}</Button>
        <Card className="p-3 text-center border mt-3">
        <h5 className="mb-0">{mostrarTarifaActual()}</h5>
        </Card>
        <Row>
            <Col md={12} className="mt-3">
            <Accordion>
            <Card key={`segmento`} className="p-0" >
            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`segmento`}>
            <div className="hover pl-3 pr-3 pb-0"><h4 className="m-0"><RiMoneyDollarCircleFill /> Segmentos de precio</h4></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`segmento`}><div className="p-3">{mostrarSegmentos()}</div></Accordion.Collapse>
            </Card>
            <Card key={`segmento-precio`} className="p-0" >
            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`segmento-precio`}>
            <div className="hover pl-3 pr-3 pb-0"><h4 className="m-0"><FaRegClock /> Segmentos de horario</h4></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`segmento-precio`}><div className="p-3">{mostrarSegmentosSemana()}</div></Accordion.Collapse>
            </Card>
            </Accordion>
            </Col>
        </Row>
    </div>
}

export default EdicionTarifa