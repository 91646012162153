import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'
import Header from '../Header'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { key_local_storage_user } from '../../lib/backend/data'

class Home extends Component {
    constructor(){
        super()
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadinguser: true,
            loadinginsignias: true,
            insignias: []
        }
    }

    ifactive(status){
      switch(status){
        case true:
          return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
      }
    }
    
    misDatos(){
      const { user } = this.state
      return <Row className="bienvenida">

      
        <Col xs={12} className="mt-3"> <h5>Mis datos</h5>  </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Estado del usuario</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{this.ifactive(user.data.activo) }</p>
          </Card.Body>
        </Card>
        </Col>


        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Email</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{user.data.email }</p>
          </Card.Body>
        </Card>
        </Col>

      </Row>

    }

    render(){
        const { user } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Inicio</h1>
        
      </div>

      <h3 style={{ fontWeight:100}}>Bienvenido {user ?  <b>{user.data.nombres}</b> : false } </h3>

      {
        this.misDatos()
      }
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Home);