import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login';
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/Home';
import { PrivateRoute } from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-loading-skeleton/dist/skeleton.css'
import CentroPedidos from './components/CentroPedidos';
import Establecimientos from './components/Establecimientos';
import InformeVentas from './components/InformeVentas'
import Envios from './components/Envios';

require('dotenv').config()

const App = <div>
  <ToastContainer
          position="bottom-right"
          theme="colored"
          />
  <Provider store={store}>
    <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/informe-ventas" component={InformeVentas} />
      <PrivateRoute exact path="/establecimientos" component={Establecimientos} />
      <PrivateRoute exact path="/orderscenter-:id" component={CentroPedidos} />
      <PrivateRoute exact path="/envios" component={Envios} />
      <Route path="/login" component={Login} />
    </Switch>
  </BrowserRouter>
  </Provider>
  </div>

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
