const debug = false

const datos = {
    urlapi: debug === true ? 'http://localhost:5001/api/1.0' : 'https://tremus-shopper.delpasystem.com/api/1.0',
    url_suite: debug === true ? 'http://localhost:5000/api/1.0' : 'https://back.delpasystem.com/api/1.0',
    key_local_storage_user: 'tremus_bumer_user',
    pagina: 20,
    web_url: "https://tremus.cl"
}

module.exports = datos